  .navbar {
    display: flex;
    flex-direction: row;
  }
  
  .navbar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    color: inherit;
    text-decoration: inherit;
    text-transform: uppercase;
    outline: none;
  }
  
  .navbar-item:active {
    text-decoration: underline;
  }
  
  Link.active {
    text-decoration: underline;
  }