.img {
    border-radius: 50%;
    width: 60%;
    height: 60%;
}

.intro {
    max-width: 100%;
    max-height: 100%;
    display: flex;
}

.description {
    padding-top: 2%;
    text-align: center;
    width: 50%;
    display: block;
    padding-bottom: 5%;
}

.image {
    padding-left: 10%;
    padding-right: 5%;
    width: 50%;
    display: block;
}

.about {
    display: contents;
    padding-top: 5px;
    position: absolute;
}

