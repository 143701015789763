.work {
    border: solid 1px;
    border-width: 1px;
    position: absolute;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.25);
}

.work-padding {
    display: flex;
    padding-right: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
}

.intro {
    display: block;
    width: 80%;
    padding-right: 10px;
    padding-top: 30px;
    position: relative;
}

.work-space {
    width: 100%;
    position: relative;
}

.wrap {
    white-space: pre-line;
    height: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

}

.carousel {
    align-self: center;
}